import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Link, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0 80px 0",
	"sm-padding": "60px 0 60px 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "80%",
			"margin": "0 auto",
			"flex-direction": "column",
			"align-items": "center",
			"lg-width": "100%",
			"lg-align-items": "center",
			"lg-margin": "0px 0px 0px 0px",
			"sm-margin": "0px 0px 0px 0px",
			"sm-padding": "0px 0px 0px 0px",
			"padding": "16px 16px 16px 0px",
			"justify-content": "center",
			"lg-order": "1"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 24px 0px",
			"color": "--dark",
			"font": "--headline2",
			"lg-text-align": "center",
			"sm-font": "normal 700 42px/1.2 \"Source Sans Pro\", sans-serif",
			"children": "Contact us"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 40px 0px",
			"color": "--greyD3",
			"font": "--lead",
			"text-align": "center",
			"children": "Raise your fitness journey at Fopalis Fitness."
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "/contacts",
			"padding": "12px 54px 12px 54px",
			"color": "--light",
			"text-decoration-line": "initial",
			"font": "--lead",
			"margin": "0px 16px 0px 0px",
			"transition": "background-color 0.2s ease-in-out 0s",
			"sm-margin": "0px 0px 16px 0px",
			"sm-text-align": "center",
			"background": "#3f24d8",
			"hover-transition": "background-color 0.2s ease-in-out 0s",
			"hover-background": "--color-primary",
			"children": "Contact us"
		}
	}
};

const Contact = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
			<Link {...override("link")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Contact, { ...Section,
	defaultProps,
	overrides
});
export default Contact;